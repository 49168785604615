import SecondContactUsFormV2 from '@components/_v2/SecondContactUsForm';
import { Carousel, Col, Grid, Row, Space } from 'antd';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { FC, memo, useRef } from 'react';
import LazyLoad from 'react-lazy-load';

type propsContactUsForm = {
  titleForm?: string;
  subtitleForm?: string;
  textSendMessage?: string;
};

const listAuthor = [
  {
    name: 'clients.mr_thi_thuong',
    role: 'clients.ceo_founder',
    roleAt: 'clients.polka_foundry',
    desc: 'clients.as_a_blockchain',
    avatar: '/images/clients/mrthithuong.jpg',
  },
  {
    name: 'clients.mr_leo',
    role: 'clients.ceo',
    roleAt: 'clients.fizen',
    desc: 'clients.their_management',
    avatar: '/images/clients/mrleovu.jpg',
  },
  {
    name: 'clients.mr_kuanzang',
    role: 'clients.project_lead',
    roleAt: 'clients.vulture_peak',
    desc: 'clients.the_team_is_well',
    avatar: '/images/clients/mrkuanzang.jpg',
  },
  {
    name: 'clients.mr_nick',
    role: 'clients.ceo',
    roleAt: 'clients.age_of_gods',
    desc: 'clients.the_ekotek_team',
    avatar: '/images/clients/mrnick.jpg',
  },
  {
    name: 'clients.mr_akihisa',
    role: 'clients.blockchain_advisor',
    roleAt: 'clients.blockchain_japan',
    desc: 'clients.blockchain_development',
    avatar: '/images/clients/mrakihisa.png',
  },
];

const settingsDefault = {
  dots: true,
  draggable: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  swipeToSlide: true,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  className: 'carousel-portfolios',
};

const { useBreakpoint } = Grid;

const ContactUsForm: FC<propsContactUsForm> = ({ titleForm, subtitleForm, textSendMessage }) => {
  const { t } = useTranslation();
  const { md, sm } = useBreakpoint();
  const slider = useRef(null);

  return (
    <div className="collect-email-form">
      <Row className="collect-container">
        <SecondContactUsFormV2
          titleForm={titleForm || (sm ? t('v3_homepage.see_how_we_can') : t('v3_homepage.see_how_we_can_mobile'))}
          subtitleForm={subtitleForm || t('v3_homepage.leave_your_email')}
          textSendMessage={textSendMessage || t('common.textSendMessages')}
        />

        <Col className="section-author">
          <LazyLoad height={md ? 566 : 170} offset={500}>
            <Carousel ref={slider} {...settingsDefault}>
              {listAuthor.map((author, idx) => (
                <div key={idx} className="author-container">
                  <Image src="/icons/icon-quote-v3.svg" alt="quote-icon" width={36} height={36} quality={100} />

                  <p>{t(author.desc)}</p>

                  <Image
                    src={author.avatar}
                    alt={t(author.name)}
                    className="avatar"
                    width={64}
                    height={64}
                    quality={100}
                  />

                  <span className="name">{t(author.name)}</span>

                  <Space size={14}>
                    <span>{t(author.role)}</span>
                    <div className="small-circle" />
                    <span>{t(author.roleAt)}</span>
                  </Space>
                </div>
              ))}
            </Carousel>
          </LazyLoad>
        </Col>
      </Row>
    </div>
  );
};

export default memo(ContactUsForm);
